<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="max800">
                    <p>I understand that telehealth is a mode of providing services remotely using telecommunication technologies to facilitate treatment. Telehealth psychotherapy is the practice of delivering clinical healthcare services via electronic means between a clinician and a client who are in two different locations.</p>
                    <p>By signing this form, I understand:</p>
                    <ul>
                        <li>I understand that I have the right to withdraw consent at any time without affecting my right to future care or services to which I would otherwise be entitled.</li>
                        <li>I understand that there are risks associated with participating in Telehealth including, but not limited to, disruption of transmission by technology failures, interruption and/or breaches of confidentiality by unauthorized persons, despite reasonable efforts and safeguard by my clinician, and/or limited ability to respond to emergencies.</li>
                        <li>I understand there will be no recordings of any sessions by either party without proper permission. All information disclosed within sessions and/or written records pertaining to those sessions are confidential and may not be disclosed without proper written authorization unless in a situation permissible by law.</li>
                        <li>I understand that the privacy laws that protect the confidentiality of my records (HIPAA) also apply to telehealth unless an exception to confidentiality applies as permissible by law and outlined in the Informed Consent.</li>
                        <li>I understand that if I am having suicidal or homicidal thoughts, actively experiencing psychotic symptoms or experiencing a mental health crisis that cannot be resolved remotely, it may be determined by my clinician that telehealth services are not appropriate, and a higher level of care is required.</li>
                        <li>I understand that during a telehealth session we could encounter technical difficulties resulting in service interruptions. If this occurs, I will end and restart the session. If in ten minutes reconnection is not possible, I will call {{ company.company_name }} at {{ company.phone }} to discuss my options.</li>
                    </ul>
                    <p>Signing below indicates you have read and understand this form. You are agreeing that you understand the risks and benefits of telehealth procedures. You are confirming you have been given the opportunity to ask questions on any portions of this form that are unclear to you.</p>
                </div>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />            
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'

    export default {
        name: 'TelehealthInformedConsent',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
            }
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }
    }
</script>